<template>
  <div class="programDetails">
    <!-- 左侧内容 -->
    <div class="programDetails_left">
      <!-- 内容 -->
      <div class="programDetails_left_content">
        <!-- 内容标题 -->
        <div class="programDetails_left_contentTitle">
          <div>{{ main.title }}</div>
          <div class="programDetails_left_contentData">
            <div>
              <div>
                <i class="el-icon-view"></i>
              </div>
              <div>{{ visitNumber }}</div>
            </div>
            <div>
              <div>
                <i class="iconfont icondianzan"></i>
              </div>
              <div>{{ main.like_num }}</div>
            </div>
          </div>
        </div>
        <!-- 内容模块 -->
        <div class="programDetails_left_contentModel">
          <!-- PPT与缩略图 -->
          <div class="left_contentModel_PPT">
            <el-carousel
              height="600px"
              indicator-position="none"
              :autoplay="!dialogVisible"
            >
              <el-carousel-item v-for="(item, index) in view_imgs" :key="index">
                <div class="left_contentModel_main">
                  <img :src="item.img_url" @click="openBigImg(index)" />
                </div>
              </el-carousel-item>
            </el-carousel>
            <!-- <div
              class="left_contentModel_main"
              v-for="(item, index) in view_imgs"
              :key="index"
            >
              <img :src="item.img_url" @click="openBigImg(item.img_url)" />
            </div> -->
          </div>
          <!-- 标签与简介 -->
          <div class="left_contentModel_bottom">
            <div class="left_contentModel_mark">
              <div style="color: #000">标签：</div>
              <div>
                <div>{{ main.cat_title }}</div>
              </div>
            </div>
            <div class="left_contentModel_outline">
              <div style="color: #000">简介：</div>
              <div>{{ main.info }}</div>
            </div>
          </div>
        </div>
        <!-- 用户操作栏 -->
        <div class="programDetails_left_contentAction">
          <div class="contentAction_left">
            <!-- <div>
              <div>
                <i class="iconfont iconjinggao"></i>
              </div>
              <div>纠错</div>
            </div>
            <div>
              <div>
                <i class="iconfont iconqizhi"></i>
              </div>
              <div>举报</div>
            </div> -->
          </div>
          <div
            class="contentAction_center"
            @click="like()"
            :style="
              islikeStatus
                ? 'background-color: #F1DC64; color: #000;border:none;'
                : ''
            "
          >
            <div>
              <i class="iconfont icondianzan"></i>
            </div>
            <div>赞（{{ main.like_num }}）</div>
          </div>
          <div class="contentAction_right">
            <div @click="favorites()">
              <div v-if="!iscollStatus">
                <i class="iconfont iconaixin-xian"></i>
              </div>
              <div v-else>
                <i class="iconfont iconaixin-kuai"></i>
              </div>
              <div>收藏</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 评论 -->
      <div class="programDetails_left_comment">
        <!-- 评论操作模块 -->
        <div class="comment_input">
          <div>
            <el-input
              :autosize="{ minRows: 4, maxRows: 4 }"
              type="textarea"
              placeholder="请输入内容"
              v-model="commentInput"
            ></el-input>
          </div>
          <div class="comment_input_btn">
            <!-- <div class="comment_input_btnUp">
              <el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/">
                <i class="el-icon-picture"></i>
              </el-upload>
            </div>-->
            <div class="comment_input_btnConfirm">
              <button @click="commentBtn()" style="">评论</button>
            </div>
          </div>
        </div>
        <!-- 评论显示模块 -->
        <div class="comment_reveal">
          <div class="comment_reveal_num">
            <div>全部评论{{ comment.length }}条</div>
          </div>
          <div
            class="comment_reveal_content"
            v-for="(item, index) in comment"
            :key="index"
          >
            <div class="comment_reveal_contentImg">
              <img :src="item.pic" />
            </div>
            <div class="comment_reveal_contentInfo">
              <div>{{ item.username }}</div>
              <div>{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 同类型推荐 -->
      <div class="programDetails_left_recommend">
        <!-- 推荐栏标题 -->
        <div>你可能在找这些</div>
        <!-- 推荐内容 -->
        <shared-list :cat_id="main.cat_id"></shared-list>
      </div>
    </div>
    <!-- 右侧用户信息 -->
    <div class="programDetails_right">
      <div ref="fileList">
        <user-model :userInfo="userInfo"></user-model>
      </div>
      <div
        :class="
          infoStatus
            ? 'programDetails_right_info programDetails_right_infos'
            : 'programDetails_right_info'
        "
      >
        <div class="programDetails_right_infoTitle">文件信息</div>
        <div class="programDetails_right_infoList">
          <div class="programDetails_right_infoItem">
            <div>浏览页数：</div>
            <div>{{ main.page_num }}</div>
          </div>
          <div class="programDetails_right_infoItem">
            <div>发布时间：</div>
            <div>{{ main.created }}</div>
          </div>
          <div class="programDetails_right_infoItem">
            <div>文件大小：</div>
            <div
              style="
                display: flex;
                align-items: center;
                justify-content: flex-start;
              "
            >
              {{
                main.file_size > 100000
                  ? (main.file_size / 1024 / 1024).toFixed(2)
                  : (main.file_size / 1024).toFixed(2)
              }}
              {{ main.file_size > 100000 ? "MB" : "KB" }}
              <!-- PPT -->
              <svg
                style="margin-left: 10px"
                t="1602122214625"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="51143"
                width="20"
                height="20"
                v-if="file_type == 'pptx' || file_type == 'ppt'"
              >
                <path
                  d="M538.731891 0h65.98683v107.168391c124.387582 0.722484 248.895579-1.324553 373.28316 0a40.699906 40.699906 0 0 1 45.034808 46.118533c2.047037 222.404516 0 444.929445 1.204139 667.454374-1.204139 24.082785 2.287865 50.694262-11.198495 72.248354-16.978363 12.041392-39.014111 10.957667-59.002822 12.041392-116.319849-0.60207-232.639699 0-349.200376 0V1023.518344h-72.248354C355.100659 990.886171 177.490122 960.662277 0 928.752587V95.488241C179.537159 63.698965 359.074318 31.30762 538.731891 0z"
                  fill="#D24625"
                  p-id="51144"
                ></path>
                <path
                  d="M604.718721 142.931326H988.598307v726.216369H604.718721v-95.247413h279.239887v-47.563499H604.718721v-60.206962h279.239887v-46.96143H604.839135v-69.960489c46.118532 14.570085 98.619003 14.208843 139.800564-14.088429 44.553151-27.093133 67.793039-78.630292 71.646284-130.047036H663.119473c0-51.777987 0.60207-103.555974-0.963311-155.213547-19.145814 3.732832-38.171214 7.826905-57.196614 12.041392z"
                  fill="#FFFFFF"
                  p-id="51145"
                ></path>
                <path
                  d="M686.35936 224.69238a165.689558 165.689558 0 0 1 153.16651 156.5381c-51.055503 0.60207-102.111007 0-153.286924 0 0.120414-52.380056 0.120414-104.278457 0.120414-156.5381z"
                  fill="#D24625"
                  p-id="51146"
                ></path>
                <path
                  d="M186.64158 314.521167c63.21731 3.130762 139.680151-25.527752 192.662277 22.878645 50.092192 62.374412 36.84666 176.888053-37.44873 214.095955-26.370649 13.847601-56.714958 12.041392-85.373471 10.957667v139.68015l-69.238006-5.900282c-1.806209-127.157103-2.047037-254.434619-0.60207-381.712135z"
                  fill="#FFFFFF"
                  p-id="51147"
                ></path>
                <path
                  d="M255.759172 378.942615c22.878645-0.963311 51.296331-5.298213 66.709313 16.737536a87.902164 87.902164 0 0 1 1.565381 78.148635c-13.245532 24.082785-43.228598 22.035748-66.468485 24.925682-2.408278-39.857008-2.167451-79.714017-1.806209-119.811853z"
                  fill="#D24625"
                  p-id="51148"
                ></path>
              </svg>
              <!-- PDF -->
              <svg
                style="margin-left: 10px"
                t="1602122330448"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="51414"
                width="20"
                height="20"
                v-if="file_type == 'pdf'"
              >
                <path
                  d="M910.9 958.5H278.3c-29.9 0-54.2-24.3-54.2-54.2v-787c0-29.9 24.3-54.2 54.2-54.2h491.3c10 0 18.1 8.1 18.1 18.1v135.6H947c10 0 18.1 8.1 18.1 18.1v669.4c0 29.8-24.3 54.2-54.2 54.2zM278.3 99.3c-10 0-18.1 8.1-18.1 18.1v786.9c0 10 8.1 18.1 18.1 18.1h632.6c10 0 18.1-8.1 18.1-18.1V252.9H769.5c-10 0-18.1-8.1-18.1-18.1V99.3H278.3z"
                  fill="#E00C3C"
                  p-id="51415"
                ></path>
                <path
                  d="M947.4 253H769.9c-10 0-18.1-8.1-18.1-18.1V81.2c0-7.1 4.1-13.5 10.6-16.4 6.4-2.9 14-1.9 19.3 2.8L801.9 85c0.2 0.1 0.3 0.3 0.5 0.4l156.9 135.8c5.7 4.9 7.7 12.9 5.1 20-2.7 7.1-9.4 11.8-17 11.8zM788 216.8h111l-111-96.1v96.1zM664.5 781.5H79.4c-11.4 0-20.7-9.3-20.7-20.7V490.6c0-11.4 9.3-20.7 20.7-20.7h585.2c11.4 0 20.7 9.3 20.7 20.7v270.2c-0.1 11.4-9.4 20.7-20.8 20.7z"
                  fill="#E00C3C"
                  p-id="51416"
                ></path>
                <path
                  d="M113.9 521.7h68.3c46.1 0 81.5 16 81.5 65.2 0 47.5-35.7 68.8-80.4 68.8h-27.8v73.9h-41.6V521.7z m66.6 101.2c28.7 0 42.4-12.1 42.4-36 0-24.2-15.2-32.3-43.8-32.3h-23.6v68.3h25zM301.3 521.7h57c63.5 0 102.8 32.9 102.8 103.1 0 70-39.3 104.8-100.6 104.8h-59.3V521.7z m54.5 174.5c38.8 0 62.9-20.8 62.9-71.4 0-50.9-24.2-70-62.9-70h-12.9v141.3h12.9zM501.9 521.7H630v34.6h-86.5v54.2h73.9v34.8h-73.9v84.3h-41.6V521.7z"
                  fill="#FFFFFF"
                  p-id="51417"
                ></path>
              </svg>
              <!-- DOC -->
              <svg
                style="margin-left: 10px"
                t="1606119970833"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3987"
                width="20"
                height="20"
                v-if="file_type == 'doc'"
              >
                <path
                  d="M535.119473 0h69.599248v95.247413C729.226717 96.331138 853.614299 93.92286 977.881468 96.331138a40.459078 40.459078 0 0 1 44.914393 45.516463c2.047037 234.566322 0 469.614299 1.204139 703.819379-1.204139 24.082785 2.287865 50.694262-11.318909 72.248354-16.978363 12.041392-38.893697 10.837253-58.761994 12.041392h-349.200376V1023.518344h-72.248354C354.980245 990.886171 177.490122 960.541863 0 928.752587V95.488241C178.33302 63.578551 356.786453 32.511759 535.119473 0z"
                  fill="#2A5699"
                  p-id="3988"
                ></path>
                <path
                  d="M604.718721 131.010348H988.598307v761.979304H604.718721v-95.247413h302.479774v-48.165569H604.718721v-59.002822h302.479774v-48.16557H604.718721v-59.002822h302.479774v-48.165569H604.718721v-60.206961h302.479774V428.673565H604.718721v-60.206961h302.479774v-46.96143H604.718721v-59.604892h302.479774V214.336783H604.718721zM240.827846 341.373471c22.156162-1.324553 44.19191-2.287865 66.348071-3.492003 15.533396 80.4365 31.30762 160.632173 48.165569 240.827845 13.125118-82.724365 27.695202-165.087488 41.783632-247.571025 23.239887-0.842897 46.479774-2.167451 69.719661-3.612418-26.370649 115.356538-49.369708 231.796802-78.148636 346.430856-19.386642 10.355597-48.165569 0-71.52587 1.204139C301.034807 596.169332 283.093133 517.779868 269.245532 438.667921c-13.606773 76.944497-31.30762 153.16651-46.841016 229.508937-22.39699-1.204139-44.793979-2.528692-67.311383-4.094073-19.266228-104.760113-42.024459-208.918156-60.206962-313.919097 19.868297-0.963311 39.857008-1.806209 60.206962-2.528693 12.041392 75.860771 25.648166 151.360301 36.124177 227.341487 16.135466-77.907808 32.873001-155.695202 49.610536-233.603011z"
                  fill="#FFFFFF"
                  p-id="3989"
                ></path>
              </svg>
              <!-- Excel -->
              <svg
                style="margin-left: 10px"
                t="1606120080536"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="7646"
                width="20"
                height="20"
                v-if="file_type == 'xlsx'"
              >
                <path
                  d="M943.5 179.7H643.2v55.6h88.5v87.9h-88.5v28h88.5v88h-88.5V468h88.5v83.2h-88.5v33.4h88.5V668h-88.5v33.4h88.5v83.9h-88.5v61.2h300.3c4.7-1.4 8.7-7 11.9-16.7 3.2-9.8 4.8-17.7 4.8-23.8V189.8c0-4.8-1.6-7.7-4.8-8.7-3.9-1-7.9-1.5-11.9-1.4z m-39 605.5h-144v-83.8h144.1l-0.1 83.8z m0-117.2h-144v-83.5h144.1l-0.1 83.5z m0-116.8h-144v-82.9h144.1l-0.1 82.9z m0-112h-144v-87.9h144.1l-0.1 87.9z m0-116.5h-144v-87.4h144.1v88l-0.1-0.6zM63.8 165.8v694.7L592.7 952V72L63.8 166.1v-0.3z m313.5 525.5c-2-5.5-11.5-28.6-28.3-69.6-9.7-23.9-19.7-47.8-29.8-71.6h-0.9l-56.7 135-75.8-5.1 89.8-168-82.4-168 77.3-4.1 51.1 131.5h1l57.7-137.5 79.9-5-95.1 181.9 98 185.5-85.8-5z"
                  fill="#107B0F"
                  p-id="7647"
                ></path>
              </svg>
            </div>
          </div>
          <div class="programDetails_right_infoItem">
            <div>收藏方案：</div>
            <div>
              <div @click="favorites()" class="collection_scheme">
                <div v-if="!iscollStatus">
                  <i class="iconfont iconaixin-xian"></i>
                </div>
                <div v-else>
                  <i class="iconfont iconaixin-kuai"></i>
                </div>
                <div>收藏</div>
              </div>
            </div>
          </div>
          <div
            class="programDetails_right_infoItem"
            style="margin-bottom: 20px"
          >
            <div>方案评分：</div>
            <div>
              <el-rate
                v-model="gradeNumber"
                :colors="colors"
                allow-half
                @change="setGradeNumber"
              >
              </el-rate>
            </div>
          </div>
          <div v-if="main.down_url">
            <el-button
              type="danger"
              size="small"
              @click="downPlan(main.down_url)"
            >
              点击下载
            </el-button>
          </div>
          <div v-else class="qrBox">
            <button
              class="qr_down"
              size="mini"
              type="danger"
              @click="qr_polling()"
            >
              点击下载
            </button>
            <div :class="Expand_QR_code ? 'qrBox_img' : 'qrBox_imgs'">
              <img :src="`data:image/jpeg;base64,${main.apply_qr}`" />
              <div>温馨提示: 邀请策划主管使用OA授权扫码</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 幻灯片界面 -->
    <div
      class="lantern_main_box flex"
      v-if="dialogVisible"
      @click="onCloseView"
      @mousemove="methodMoveLanternView"
    >
      <div
        class="lantern_left_main_box flex-center"
        @mousewheel="methodScrollLanternView"
        :style="{
          width: isPart ? 'calc(100vw - 212px)' : '100vw',
        }"
      >
        <div
          class="lantern_img_box flex-center"
          :style="{ width: showImgW + 'px', height: showImgH + 'px' }"
          @click.stop="onImage"
          :class="{ lantern_anim1: showAnim, lantern_anim2: !showAnim }"
          v-if="!inResize"
        >
          <el-image
            style="width: 100%; height: 100%"
            :src="view_imgs[lantern_show_index].img_url"
          ></el-image>
          <div
            v-if="isShowOperate"
            class="lantern_like_box flex"
            @click="favorites()"
          >
            <div v-if="!iscollStatus">
              <i
                class="iconfont iconaixin-kuai"
                style="color: #fff; font-size: 13px; margin-left: 12px"
              ></i>
            </div>
            <div v-else>
              <i
                class="iconfont iconaixin-kuai"
                style="color: #ff4757; font-size: 13px; margin-left: 12px"
              ></i>
            </div>
            <div style="color: #fff; font-size: 13px; margin-right: 15px">
              收藏
            </div>
          </div>
        </div>
        <div :style="{ opacity: isShowOperate ? '1' : '0' }">
          <div class="page_box flex" v-if="!inResize">
            {{ lantern_show_index + 1 }} / {{ view_imgs.length }}
          </div>
          <div class="mune_box flex">
            <div class="btn_box flex-center" @click.stop="onOperateBtn('play')">
              <i
                class="newiconfont icon-zanting"
                style="font-size: 20px"
                v-if="isPlay"
              ></i>
              <i
                class="newiconfont icon-kaishi"
                style="font-size: 20px"
                v-else
              ></i>
            </div>
            <div class="btn_box flex-center" @click.stop="onOperateBtn('full')">
              <i
                class="newiconfont icon-fangdazhanshi"
                style="font-size: 22px"
                v-if="isFull % 2 == 0"
              ></i>
              <i class="newiconfont icon-suoxiao" v-else></i>
            </div>
            <div class="btn_box flex-center" @click.stop="onOperateBtn('part')">
              <i class="el-icon-s-grid" v-if="isPart"></i>
              <i class="el-icon-menu" v-else></i>
            </div>
            <div
              class="btn_box flex-center"
              @click.stop="onOperateBtn('close')"
            >
              <i class="newiconfont icon-guanbi"></i>
            </div>
          </div>
          <div
            class="prev_box flex-center"
            :style="{ top: clientH / 2 - 19 + 'px' }"
            @click.stop="methodNextImage('up')"
          >
            <i class="el-icon-back" style="color: #fff; font-size: 20px"></i>
          </div>
          <div
            class="next_box flex-center"
            :style="{ top: clientH / 2 - 19 + 'px' }"
            @click.stop="methodNextImage('next')"
          >
            <i class="el-icon-right" style="color: #fff; font-size: 20px"></i>
          </div>
        </div>
        <div
          v-if="isPlay && !inResize"
          class="progress_box"
          :style="{ right: leftImgW + 'px' }"
          :class="{
            progress_anim1: progressAnim,
            progress_anim2: !progressAnim,
          }"
        ></div>
      </div>
      <div class="lantern_right_main_box" @click.stop="onImage" v-show="isPart">
        <div
          v-for="(item, index) in view_imgs"
          :key="index"
          class="thumb_box"
          @click="onImageChange(index)"
        >
          <!-- <img :src="item.img_url" style="width: 100%; height: 100%" /> -->
          <el-image
            style="width: 100%; height: 100%"
            :src="item.img_url"
            fit="cover"
          ></el-image>
          <div
            class="thumb_border"
            :class="{
              thumb_sel: index == lantern_show_index,
              thumb_def: index != lantern_show_index,
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userModel from "@/components/userModel.vue";
import SharedList from "@/components/SharedList.vue";
export default {
  data() {
    return {
      userInfo: {},
      infoStatus: false,
      view_imgs: [],
      main: {},
      // 评论
      comment: [],
      // 评论输入框
      commentInput: "",
      // 收藏状态
      iscollStatus: true,
      // 点赞状态
      islikeStatus: true,
      // 方案文件类型
      file_type: "",
      // 展开二维码
      Expand_QR_code: true,
      // 二维码过去时间
      expireDate: 24,
      // 累积时间
      totalTime: 0,
      // 时间状态
      timeStatus: null,
      // 图片弹窗
      dialogVisible: false,
      // 大图路径
      bigImg: "",

      visitNumber: "0",
      likeNumber: "0",

      gradeNumber: "",
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],

      windowW: 0,
      windowH: 0,

      lantern_show_index: 0,
      showAnim: false,

      clientW: 800,
      clientH: 450,
      leftImgW: 0,
      showImgW: 0,
      showImgH: 0,
      inResize: false,

      isScroll: true,
      isShowOperate: true,
      showOperateTime: "",
      isPart: true,
      isPlay: false,
      isFull: 0,

      playTime: "",
      playProgress: 0,
      progressAnim: true,
    };
  },
  components: {
    userModel,
    SharedList,
  },
  created() {
    this.windowW = window.screen.width;
    this.windowH = window.screen.height;
    let visit = this.$route.query.visit;
    let like = this.$route.query.like;
    if (visit) {
      this.visitNumber = visit;
    }
    if (like) {
      this.likeNumber = like;
    }
    this.getHomeDetails();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);

    this.methodScreenChange();

    const that = this;
    window.onresize = () => {
      return (() => {
        this.$nextTick(() => {
          that.methodScreenChange();
        });
      })();
    };
  },
  destroyed() {
    clearInterval(this.timeStatus);
    clearInterval(this.showOperateTime);
    clearInterval(this.playTime);
    window.removeEventListener("scroll", this.handleScroll, true); //  离开页面清除（移除）滚轮滚动事件
    let id = setTimeout(() => {}, 0);
    while (id > 0) {
      window.clearTimeout(id);
      id--;
    }
  },
  methods: {
    handleScroll: function () {
      let scrollTop = this.$refs.fileList.getBoundingClientRect().top || null;
      let scrollHeight = this.$refs.fileList.offsetHeight;
      let scrollBottom = scrollTop + scrollHeight;
      if (scrollBottom <= 0) {
        this.infoStatus = true;
      } else {
        this.infoStatus = false;
      }
    },
    // 放大图片
    openBigImg(url) {
      this.dialogVisible = true;
      this.stop();
      // this.bigImg = url;
    },
    // 获取详情信息
    getHomeDetails() {
      let id = this.$route.query.id;
      this.$axios({
        url: this.$api + "/auth/ppt/details",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          item_id: id,
        },
      }).then((res) => {
        this.view_imgs = res.data.data.view_imgs;
        this.file_type = res.data.data.file_type;
        this.main = res.data.data.main;
        // let qr = window.atob(this.main.apply_qr);
        // console.log(qr);
        this.comment = res.data.data.comment;
        this.userInfo = {
          username: this.main.username,
          picture: this.main.picture,
          product_num: this.main.product_num,
        };
        this.iscollStatus = res.data.data.iscoll;
        this.islikeStatus = res.data.data.islike;
      });
    },
    // 评论
    commentBtn() {
      let id = this.$route.query.id;
      if (this.commentInput == "") {
        this.$message({
          showClose: true,
          message: "评论内容不能为空",
          type: "warning",
        });
        return;
      }
      this.$axios({
        url: this.$api + "/auth/ppt/comment/insert",
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8;",
        },
        data: {
          comment: this.commentInput,
          item_id: id,
        },
      }).then((res) => {
        this.commentInput = "";
        this.getHomeDetails();
      });
    },
    // 收藏
    favorites() {
      let id = this.$route.query.id;
      this.$axios({
        url: this.$api + "/auth/ppt/collection",
        method: "PUT",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          item_id: id,
          cat: 2,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.iscollStatus = !this.iscollStatus;
        }
      });
    },
    like() {
      let id = this.$route.query.id;
      this.$axios({
        url: this.$api + "/auth/ppt/collection",
        method: "PUT",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          item_id: id,
          cat: 1,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.islikeStatus = !this.islikeStatus;
          this.getHomeDetails();
        }
      });
    },
    // 下载方案
    downPlan(down_url) {
      if (down_url != "") {
        let id = this.$route.query.id;
        this.$axios({
          url: this.$api + "/auth/ppt/download/note",
          method: "GET",
          headers: {
            Authorization: localStorage.getItem("user_token"),
            "Content-Type": "application/json;charset=UTF-8;",
          },
          params: {
            item_id: id,
          },
        }).then((res) => {
          if (res.data.code == 200) {
            window.open(down_url, "_blank");
            this.getHomeDetails();
          } else {
            console.log(res.data.msg);
          }
        });
      }
    },
    // 下载链接轮询
    qr_polling() {
      this.Expand_QR_code = !this.Expand_QR_code;
      this.timeStatus = setInterval(() => {
        this.qr_downRequest();
        this.totalTime += 1;
      }, 5000);
    },
    // 下载的链接
    qr_downRequest() {
      let id = this.$route.query.id;
      this.$axios({
        url: this.$api + "/auth/ppt/downlaod/ppt_url",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          item_id: id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.main.down_url = res.data.data;
          clearInterval(this.timeStatus);
        } else {
          if (this.totalTime == this.expireDate) {
            clearInterval(this.timeStatus);
            this.$message({
              showClose: true,
              message: "二维码已过期，请重新刷新",
              type: "warning",
              duration: 5000,
            });
          }
        }
      });
    },
    setGradeNumber(value) {},
    //禁止滚动
    stop() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); //禁止页面滑动
    },
    /***取消滑动限制***/
    move() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", mo, false);
    },
    onCloseView() {
      this.dialogVisible = false;
      this.isPlay = false;
      clearInterval(this.playTime);
      this.playTime = "";
      clearInterval(this.showOperateTime);
      this.showOperateTime = "";
      this.move();
    },
    methodScrollLanternView(e) {
      if (this.isScroll) {
        this.isScroll = false;
        let that = this;
        let delta = -e.wheelDelta || e.detail;

        if (delta > 0) {
          console.log("下滚");
          this.methodNextImage("next");
          setTimeout(() => {
            that.isScroll = true;
          }, 1000);
        }
        if (delta < 0) {
          console.log("上滚");
          this.methodNextImage("up");
          setTimeout(() => {
            that.isScroll = true;
          }, 1000);
        }
      }
    },
    onImage() {},
    methodNextImage(type) {
      if (type == "next") {
        if (this.lantern_show_index != this.view_imgs.length - 1) {
          this.onImageChange((this.lantern_show_index += 1));
        }
      } else {
        if (this.lantern_show_index > 0) {
          this.onImageChange((this.lantern_show_index -= 1));
        }
      }
    },
    onImageChange(index) {
      this.lantern_show_index = index;
      this.showAnim = !this.showAnim;
      this.inResize = true;
      let that = this;
      this.$nextTick((t) => {
        that.inResize = false;
      });
    },
    methodMoveLanternView() {
      clearInterval(this.showOperateTime);
      this.showOperateTime = "";
      let that = this;
      this.isShowOperate = true;
      this.showOperateTime = setTimeout(() => {
        that.isShowOperate = false;
      }, 4000);
    },
    onOperateBtn(type) {
      if (type == "play") {
        clearInterval(this.playTime);
        this.playTime = "";
        this.isPlay = !this.isPlay;
        if (this.isPlay) {
          let that = this;
          let isLast = false;
          if (that.lantern_show_index == that.view_imgs.length - 1) {
            isLast = true;
          }
          this.playTime = setInterval(() => {
            that.progressAnim = !that.progressAnim;
            if (isLast) {
              isLast = false;
              that.onImageChange(0);
            } else {
              if (that.lantern_show_index == that.view_imgs.length - 1) {
                clearInterval(that.playTime);
                that.playTime = "";
                that.isPlay = false;
              } else {
                that.methodNextImage("next");
              }
            }
          }, 3200);
        }
      } else if (type == "full") {
        this.isFull++;
        this.isFull % 2 == 0
          ? this.outFullCreeen(document)
          : this.FullCreeen(document.documentElement);
      } else if (type == "part") {
        this.isPart = !this.isPart;
        if (this.isPart) {
          if ((this.clientW - 212) * 0.563 > this.clientH - 88) {
            this.showImgH = this.clientH - 88;
            this.showImgW = this.showImgH / 0.563;
            this.leftImgW = this.clientW - 212;
          } else {
            this.leftImgW = this.clientW - 212;
            this.showImgW = this.clientW - 212;
            this.showImgH = this.showImgW * 0.563;
          }
        } else {
          if (this.clientW * 0.563 > this.clientH - 88) {
            this.leftImgW = this.clientW;
            this.showImgH = this.clientH - 88;
            this.showImgW = this.showImgH / 0.563;
          } else {
            this.leftImgW = this.clientW;
            this.showImgW = this.clientW;
            this.showImgH = this.showImgW * 0.563;
          }
        }
      } else if (type == "close") {
        this.onCloseView();
      }
    },
    // 进入全屏的方法
    FullCreeen(element) {
      let el = element;
      let rfs =
        el.requestFullScreen ||
        el.webkitRequestFullScreen ||
        el.mozRequestFullScreen ||
        el.msRequestFullScreen;
      // Element.requestFullscreen() 此方法用于发出异步请求使元素进入全屏模式。
      // 调用此API并不能保证元素一定能够进入全屏模式。如果元素被允许进入全屏幕模式，
      // document对象会收到一个fullscreenchange事件，通知调用者当前元素已经进入全屏模式。如果全屏请求不被许可，则会收到一个fullscreenerror事件。
      if (typeof rfs != "undefined" && rfs) {
        rfs.call(el);
      } else if (typeof window.ActiveXObject != "undefined") {
        let wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
          wscript.SendKeys("{F11}");
        }
      }
    },
    //退出全屏的方法
    outFullCreeen(element) {
      let el = element;
      let cfs =
        el.cancelFullScreen ||
        el.webkitCancelFullScreen ||
        el.mozCancelFullScreen ||
        el.exitFullScreen;
      if (typeof cfs != "undefined" && cfs) {
        cfs.call(el);
      } else if (typeof window.ActiveXObject != "undefined") {
        let wscript = new ActiveXObject("WScript.Shell");
        if (wscript != null) {
          wscript.SendKeys("{F11}");
        }
      }
    },
    methodScreenChange() {
      this.clientW = document.body.clientWidth;
      this.clientH = document.documentElement.clientHeight;
      this.leftImgW = this.clientW - 212;
      if ((this.clientW - 212) * 0.563 > this.clientH - 88) {
        this.showImgH = this.clientH - 88;
        this.showImgW = this.showImgH / 0.563;
      } else {
        this.showImgW = this.clientW - 212;
        this.showImgH = this.showImgW * 0.563;
      }
    },
  },
};
</script>

<style scoped>
.programDetails {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1500px) {
  .programDetails {
    width: 1250px;
  }
}
.programDetails_left {
  width: 70%;
}
.programDetails_right {
  width: 30%;
  position: relative;
}
@media screen and (min-width: 4000px) and (max-width: 4500px) {
  .programDetails_right {
    width: 20%;
  }
}

/* 内容标题 */
.programDetails_left_contentTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.programDetails_left_contentData {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.programDetails_left_contentData > div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  color: #949494;
  cursor: pointer;
}
.programDetails_left_contentData > div i {
  font-size: 20px;
  margin: 10px;
}
/* 缩略图与PPT */
.left_contentModel_PPT img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.left_contentModel_main {
  width: 100%;
  /* height: 600px; */
}
.left_contentModel_bottom {
  margin-top: 20px;
  font-size: 14px;
}
.left_contentModel_bottom > div {
  padding: 10px 0;
  color: #7c7c7c;
  display: flex;
  justify-content: flex-start;
}
.left_contentModel_bottom > div > div:nth-of-type(1) {
  width: 10%;
}
.left_contentModel_bottom > div > div:nth-of-type(2) {
  width: 90%;
}
.left_contentModel_mark > div:nth-of-type(2) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.left_contentModel_mark > div:nth-of-type(2) > div {
  margin-right: 20px;
  cursor: pointer;
}
.programDetails_left_contentAction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.programDetails_left_contentAction > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.programDetails_left_contentAction > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.programDetails_left_contentAction i {
  margin-right: 5px;
}
.contentAction_left > div {
  padding: 5px 10px;
  font-size: 14px;
  color: #8b8b8b;
  margin-right: 15px;
  border-radius: 5px;
  border: 1px solid #bebebe;
}
.contentAction_center {
  margin: 0 15px;
  background-color: #606266;
  padding: 5px 10px;
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  user-select: none;
  border: 1px solid #bebebe;
}
.contentAction_center:active {
  background-color: #606266b6;
}
.contentAction_center i {
  font-size: 28px;
}
.contentAction_right > div {
  margin-left: 15px;
  padding: 5px 10px;
  font-size: 14px;
  color: #8b8b8b;
  border-radius: 5px;
  border: 1px solid #bebebe;
}
/* 评论操作 */
.programDetails_left_comment {
  margin: 20px 0;
}
.comment_input {
  margin: 20px 0;
}
.comment_input_btn {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.comment_input_btnUp {
  font-size: 24px;
}
.comment_input_btnConfirm > button {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #409eff;
  color: #fff;
  padding: 5px 15px;
  border-radius: 3px;
  font-size: 16px;
  background-color: #f1dc64;
  user-select: none;
}
.comment_input_btnConfirm > button:active {
  background-color: #f1dc64af;
}
/* 评论显示模块 */
.comment_reveal {
  border-top: 1px solid #bebebe;
  border-bottom: 1px solid #bebebe;
  padding: 20px 0;
}
.comment_reveal_content {
  display: flex;
  margin: 20px 0;
  justify-content: space-between;
  border-bottom: 1px solid #e3e3e3;
}
.comment_reveal_contentImg {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  overflow: hidden;
}
.comment_reveal_contentImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.comment_reveal_contentInfo {
  width: 90%;
  font-size: 14px;
  padding: 0 20px;
}
.comment_reveal_contentInfo > div:nth-of-type(1) {
  font-size: 16px;
}
.comment_reveal_contentInfo > div:nth-of-type(2) {
  font-size: 14px;
  color: #7c7c7c;
  margin: 10px 0;
}

/* 文件信息 */
.programDetails_right_info {
  padding: 20px;
  box-sizing: border-box;
}
.programDetails_right_infos {
  position: fixed;
  min-width: 375px;
  top: 70px;
  width: 20%;
}
.programDetails_right_infoTitle {
  font-size: 16px;
  font-weight: 600;
  margin: 20px;
}
.programDetails_right_infoList {
  font-size: 14px;
  color: #666;
  border-top: 1px solid #e3e3e3;
  margin: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.programDetails_right_infoItem {
  width: 100%;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.programDetails_right_infoItem > div:nth-of-type(1) {
  width: 30%;
}
.programDetails_right_infoItem > div:nth-of-type(2) {
  width: 65%;
}

/* 二维码 */
.qrBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: height 0.5s;
}
.qr_down {
  background-color: #f56c6c;
  border: none;
  outline: none;
  color: #fff;
  border-radius: 3px;
  padding: 5px 10px;
}
.qrBox_img {
  height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.5s;
}
.qrBox_imgs {
  height: 35vh;
  transition: all 0.5s;
}

/* 收藏按钮 */
.collection_scheme {
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 10px;
  cursor: pointer;
  width: 20% !important;
  border: 1px solid #8b8b8b;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.collection_scheme i {
  font-size: 10px;
}

/* 放大图片 */
.bigImg >>> .el-dialog {
  margin-top: 5vh !important;
}
.dialogbg >>> .el-dialog {
  background: rgba(0, 0, 0, 0);
  border: 0;
  box-shadow: none;
}

.lantern_main_box {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.lantern_left_main_box {
  height: 100%;
  position: relative;
}
.lantern_right_main_box {
  width: 212px;
  background: #ededed;
  padding: 4px 2px;
  overflow: auto;
  height: 100%;
}
.lantern_img_box {
  opacity: 0.3;
  position: relative;
}
.lantern_anim1 {
  animation: anim_show 0.5s linear forwards;
}
.lantern_anim2 {
  animation: anim_show 0.5s linear forwards;
}
@keyframes anim_show {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.thumb_box {
  width: 91px;
  height: 70px;
  margin: 2px;
  float: left;
  position: relative;
  cursor: pointer;
}
.thumb_border {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 6px solid #f1dc64;

  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.thumb_sel {
  opacity: 1;
}
.thumb_def {
  opacity: 0;
}

.operate_box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mune_box {
  position: absolute;
  top: 0;
  right: 0;
  height: 44px;
  width: 176px;
  background: rgba(0, 0, 0, 0.8);
}
.prev_box {
  position: absolute;
  left: 6px;
  height: 38px;
  width: 38px;
  background: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}
.next_box {
  position: absolute;
  right: 6px;
  height: 38px;
  width: 38px;
  background: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}
.btn_box {
  height: 44px;
  width: 44px;
  cursor: pointer;
  color: #fff;
  font-size: 20px;
}
.page_box {
  position: absolute;
  top: 5px;
  left: 20px;
  height: 44px;
  color: #fff;
}
.progress_box {
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  border-radius: 2px;
  background: #f1dc64;
}

.progress_anim1 {
  animation: pro_anim 3s linear forwards;
}
.progress_anim2 {
  animation: pro_anim 3s linear forwards;
}
@keyframes pro_anim {
  100% {
    right: 0px;
  }
}

.lantern_like_box {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 80px;
  height: 30px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
</style>