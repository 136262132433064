<template>
  <div>
    <div class="recommends_list">
      <div
        class="recommend_item"
        v-for="(item, index) in list"
        :key="index"
        @click="openDetails(item.id)"
      >
        <!-- 推荐内容图片 -->
        <div class="recommend_img">
          <img :src="item.cover" />
        </div>
        <div class="recommend_info">
          <!-- 推荐内容标题 -->
          <div>
            <div class="recommend_title">{{ item.title }}</div>
            <div class="recommend_num">
              <div>
                <div>
                  <i class="el-icon-view"></i>
                </div>
                <div>{{ item.visit_num }}</div>
              </div>
              <div>
                <div>
                  <i class="iconfont icondianzan"></i>
                </div>
                <div>{{ item.like_num }}</div>
              </div>
            </div>
          </div>
          <!-- <el-popover placement="top-start" width="200" trigger="hover" :open-delay="500">
          <user-model></user-model>-->
          <!-- 推荐内容作者 -->
          <div class="recommend_user" slot="reference">
            <!-- 用户头像 -->
            <div>
              <img :src="item.picture" />
            </div>
            <!-- 用户名称 -->
            <div>{{ item.username }}</div>
          </div>
          <!-- </el-popover> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userModel from "@/components/userModel.vue";
export default {
  props: ["cat_id"],
  data() {
    return {
      listParameter: {
        page: 1,
        title: "",
        username: "",
        cat_id: "",
        hot: "",
        new: "",
      },
      list: [],
      count: "",
    };
  },
  watch: {
    cat_id(val) {
      console.log(val);
      this.getList(val);
    },
  },
  components: {
    userModel,
  },
  methods: {
    getList(e) {
      let listParameter = this.listParameter;
      this.$axios({
        url: this.$api + "/auth/ppt/indexlist",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          page: listParameter.page,
          title: listParameter.title,
          username: listParameter.username,
          cat_id: e,
          hot: listParameter.hot,
          new: listParameter.new,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          let list = res.data.data;
          this.list = list.res;
          this.count = list.count;
        }
      });
    },
    openDetails(e) {
      let routeData = this.$router.resolve({
        path: "/HomeDetails",
        query: {
          id: e,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style scoped>
/* 推荐内容 */
.recommends_list {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.recommend_item {
  width: 30%;
  margin: 1% 1.5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
}
.recommend_img {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cacaca;
  border-bottom: none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  height: 20vh;
}
.recommend_img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.recommend_info {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 1px solid #cacaca;
  border-top: none;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}
.recommend_num {
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.recommend_num > div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #bebebe;
}
.recommend_num i {
  font-size: 18px;
  margin: 0 5px;
}
.recommend_title {
  font-size: 14px;
  margin: 10px 0;
  box-sizing: border-box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.recommend_user {
  padding: 10px 0;
  box-sizing: border-box;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid #cfcfcf;
  cursor: pointer;
}
.recommend_user > div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.recommend_user img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 100%;
}
</style>